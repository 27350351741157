import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { uniqBy } from "lodash";
import {
  Box,
  Paper,
  Stack,
  Typography,
} from "../../ui/core";
import { CalendarIcon } from "../../ui/icons";
import { useQuery, useFormatters } from "../../hooks";
import {
  TripDisplayStatusOrder,
} from "../../models/Trips";

import { getNationalTrips } from "../../redux/Trips/actions";
import TripDetailsDialog from "../../components/widgets/Trips/TripDetailsDialog";
import TripsTable from "../../components/widgets/Trips/TripsTable";
import CitiesSelector from "../../components/widgets/Trips/CitiesSelector";


const GROUP_COLUMN = {
  field: "departureDate",
  groupBy: true,
  groupExpanded: true,
  getGroupCount: ({ value, rows }) => {
    return rows.filter(
      (r) => r.departureDate === value && !r.isPrevious && !r.isNext
    ).length;
  },
  renderGroupHeader: ({ value, count }) => {
    const isPast = moment(value, "MMM DD, YYYY").startOf("day").isBefore(moment().startOf("day"))
    return (
      <Stack direction="row" spacing="10px" alignItems="center">
        <CalendarIcon sx={{ fontSize: 20, color: isPast ? "text.secondary" : "text.primary" }} />
        <Typography fontWeight="bold" color={isPast ? "text.secondary" : "text.primary"}>{value}</Typography>
        <Typography color="text.secondary" fontWeight="bold">({count})</Typography>
      </Stack>
    );
  },
}

const updateURLParams = ({ citiesIds, tripId, fullScreen }, history) => {
  const params = new URLSearchParams();
  citiesIds.length && params.append("citiesIds", citiesIds.join(","));
  if (tripId) {
    params.append("tripId", tripId);
    params.append("fullScreen", !!fullScreen);
  }
  history.push({ search: params.toString() });
};


const TripsNational = () => {
  const dispatch = useDispatch();
  const f = useFormatters();
  const history = useHistory();
  let { tripId, fullScreen, citiesIds } = useQuery();
  citiesIds = (citiesIds || '').split(',').filter((id) => !!id);

  const {
    data,
    loading: loadingData,
  } = useSelector((state) => state.trips.nationalTrips);

  const citiesOptions = useMemo(() => {
    const tripsCities = data.reduce((cities, t) => [...cities, t.originCity, t.destinationCity], [])
    return uniqBy(tripsCities, c => c.id);
  }, [data]);

  const filteredData = citiesIds.length > 0 ?
    data.filter((t) => citiesIds.includes(t.originCity.id) || citiesIds.includes(t.destinationCity.id)) :
    data;

  const today = f.date.toLocaleString(new Date(), f.date.DateFormats.DATE_ISO);

  const sortedData = filteredData
    .map((t) => ({
      trip: t,
      departureDate: f.date.toTimezoneLocaleString(
        t.departureTime,
        t.originCity.timeZone,
        f.date.DateFormats.DATE_ISO
      ),
      displayStatus: t.displayStatus,
    }))
    .sort((d1, d2) => {
      if (d1.departureDate === today) {
        return -3;
      }

      if (d1.departureDate > today) {
        return Date.parse(d1.trip.departureTime) > Date.parse(d2.trip.departureTime) ? 2 : -2
      }

      if (d1.departureDate !== d2.departureDate) {
        return Date.parse(d1.trip.departureTime) > Date.parse(d2.trip.departureTime) ? -1 : 1
      }

      return TripDisplayStatusOrder.indexOf(d1.displayStatus) - TripDisplayStatusOrder.indexOf(d2.displayStatus)
    })
    .map((d) => d.trip)

  useEffect(() => {
    getNationalTrips()(dispatch);
  }, [dispatch])

  const onRowClick = ({ row }) => updateURLParams({ citiesIds, tripId: row.id, fullScreen: true }, history);;

  const onTripDetailsClose = () => updateURLParams({ citiesIds }, history);

  const onTripDetailsFullscreen = (fullScreen) => updateURLParams({ citiesIds, tripId, fullScreen }, history);

  const onCitiesFilterChange = (value) => updateURLParams({ citiesIds: value.map((c) => c.id), tripId, fullScreen }, history);

  return (<Box>
    <Paper elevation={0}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ padding: "32px" }}
      >
        <Typography
          sx={{ fontWeight: 700, fontSize: "32px", lineHeight: "40px" }}
        >
          National Trips
        </Typography>
        <CitiesSelector
          options={citiesOptions}
          value={citiesOptions.filter((c) => citiesIds.includes(c.id))}
          onChange={onCitiesFilterChange}
        />
      </Stack>
      <div style={{ width: "100%", height: "calc(100vh - 262px)" }}>
        <TripsTable
          data={sortedData}
          loading={loadingData}
          onRowClick={onRowClick}
          getColumn={(c) => {
            if (c.field === GROUP_COLUMN.field) {
              return { ...c, ...GROUP_COLUMN };
            }
            return c;
          }}
          getRow={(row) => ({
            ...row,
            departureDate: f.date.toTimezoneLocaleString(
              row.departureTime,
              row.originCity.timeZone,
              f.date.DateFormats.DATE_MED
            ),
          })}
        />
      </div>
    </Paper>
    {
        <TripDetailsDialog
          trip={data.find((t) => t.id === tripId)}
          fullScreen={fullScreen === "true"}
          outboundTrip={
            tripId ? data.find((t) => t.nextTrip === tripId) : null
          }
          returnTrip={
            tripId ? data.find((t) => t.previousTrip === tripId) : null
          }
          onClose={onTripDetailsClose}
          onFullscreen={onTripDetailsFullscreen}
        />
      }
  </Box>)
}

export default TripsNational;
