import React, { useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useValidators, useTextMasks } from '../../../hooks';
import { TextField, CircularProgress } from '../../../ui/core';
import { decodeVIN } from '../../../redux/Drivers/actions';

const VinField = ({ control, form, idx, ...other }) => {
  const v = useValidators();
  const m = useTextMasks();
  const decodingInfo = useRef({ v: null, data: null });
  const { field, fieldState: { error }, formState: { isValidating } } = useController({
    control,
    name: `vehicles[${idx}].vin`,
    rules: {
      validate: {
        init: (val) => {
          if (decodingInfo.current.v === null) {
            decodingInfo.current.v = val;
          }
          return true;
        },
        length: v.text.length(17),
        decode: async (val) => {
          if (decodingInfo.current.v !== val) {
            const { data } = await decodeVIN(val)();
            decodingInfo.current.v = v;
            decodingInfo.current.data = data;
          }
          return true;
        },
      }
    },
  });

  const patchVehicleType = (type, make, model, year) => {
    const withYear = `${year} ${make} ${model}`
    const woYear = `${make} ${model}`

    const transform = {
      '2019 Chevrolet Trax': 'Subcompact SUV',
      '2017 Ford Fusion Hybrid': 'Mid-size Sedan',
      '2022 Nissan Pathfinder': 'Mid-size SUV',
      'Ford Explorer': 'Midsize SUV',
      'Kia Seltos': 'Subcompact SUV',
      'Mazda 3': 'Compact Sedan',
      'Nissan Sentra': 'Compact Sedan',
      'Hyundai Elantra': 'Compact Sedan',
      'Hyundai Sonata': 'Mid-size Sedan',
      'Hyundai Sonata Hybrid': 'Mid-size Sedan',
      'Honda HRV': 'Subcompact SUV',
      'Mustang Mach-E': 'Midsize Crossover UV',
      'Toyota Highlander': 'Midsize SUV',
      'Toyota Corolla Cross': 'Compact SUV',
      'Subaru Outback': 'Midsize SUV',
      'Nissan Rogue': 'Midsize SUV',
      'Toyota Rav 4': 'Compact SUV',
      'Toyota Rav 4 Hybrid': 'Compact SUV',
      'Ford Fusion Hybrid': 'Mid-size Sedan',
      'Tesla Model Y': 'Compact SUV',
      'Lincoln MKT': 'Fullsize SUV',
      'Tesla Model 3': 'Mid-size Sedan',
      'Dodge Durango': 'Midsize SUV',
    }

    return transform[withYear] || transform[woYear] || type;
  }

  const onVinDecoded = (data) => {
    // Patch known mis-classifications until we can implement a more reliable system
    // Ideally this patching would be on the backend, but it's easier to locally develop on the UI
    const vehicleType = patchVehicleType(data.type, data.make, data.model, data.year)

    const vehicle = form.getValues(`vehicles[${idx}]`);
    form.setValue(`vehicles[${idx}].make`, data.make, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].model`, data.model, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].type`, vehicleType, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].year`, data.year, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].seatCount`, vehicle.seatCount || '3', { shouldDirty: true, shouldTouch: true });
  };
  

  useEffect(() => {
    if (!isValidating && decodingInfo.current.data) {
      onVinDecoded(decodingInfo.current.data);
    }
  }, [isValidating]);

  return <TextField
    name={`vehicles[${idx}].vin`}
    label="VIN"
    mask={m.text.uppercase}
    value={field.value}
    onChange={field.onChange}
    onBlur={field.onBlur}
    inputRef={field.ref}
    error={!!error}
    helperText={error ? error.message : ''}
    InputProps={{
      endAdornment: isValidating ? <CircularProgress size={16} color="inherit" /> : null,
      readOnly: isValidating,
    }}
    {...other}
  />;
};

export default VinField;
